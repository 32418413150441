import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareNavigator, {
  EasySoftwareNavigatorType,
} from '../EasySoftwareNavigator/EasySoftwareNavigator'

export interface WidgetEasySoftwareNavigatorType
  extends WidgetBase,
  EasySoftwareNavigatorType {
  contentType: 'EasySoftwareNavigator'
}

const WidgetEasySoftwareNavigator: FunctionComponent<WidgetEasySoftwareNavigatorType> = (
  props
) => (
  <WidgetContainer {...props.puxWidgetProperties} {...props.metadata}>
    <EasySoftwareNavigator
      displayText={props.displayText}
      navigatorCategories={props.navigatorCategories}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareNavigator
